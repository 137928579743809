.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 820px;
}

.title {
  font-size: 1rem;
  font-weight: 500;

  @media screen and (min-width: 420px) {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
  }
}

.subtitle {
  font-weight: 700;
  font-size: 5rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;

  background: linear-gradient(
    to bottom,
    #b1e3eb 0%,
    #9fcff2 10.5%,
    #99aef5 20.5%,
    #a293fb 32.5%,
    #c389f0 45.5%,
    #e98ef7 59.5%,
    #f992d0 70%,
    #ffa3a3 81%,
    #ffe0a3 93.5%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  @media screen and (min-width: 420px) {
    font-size: 7.5rem;
    margin-top: -1rem;
    margin-bottom: 1.25rem;
  }
  @media screen and (min-width: 768px) {
    margin-top: -1.25rem;
    margin-bottom: 2.5rem;
    font-size: 12.5rem;
  }
}
